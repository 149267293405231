import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home";
import Layout from "./Pages/Layout";
import Products from "./Pages/Products/Products";
import Contact from "./Pages/Contact/Contact";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Values from "./Pages/Values/Values";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="values" element={<Values />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<AboutUs />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
