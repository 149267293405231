import "../App.css";
import React, { useEffect } from "react";
import VideoCard2 from "../Components/VideoCard2";
import VideoCardButton from "../Components/VideoCardButton";
import ContactForm from "../Components/ContactForm";
import { Swiper, SwiperSlide } from "swiper/react";
import { gsap } from "gsap";
import { initScrollAnimations } from "../Components/AnimationUtils";
import "../Components/Preloader.css";

import VideoCard from "../Components/VideoCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

// import required modules
import { Autoplay } from "swiper/modules";
import ValuesCard from "../Components/ValuesCard";
import Preloader from "../Components/Preloader";

// import { Video } from "cloudinary-react";

function Home() {
  useEffect(() => {
    // Animation timeline
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

    // Delay before sliding the colored divs
    tl.to({}, { duration: 1.5 }); // Delay for 5 seconds

    // Slide the colored divs
    tl.to(".lightCyan-slider", { x: "-10%", duration: 1 })
      .to(".persianGreen-slider", { x: "-20%", duration: 1.5 }, "-=1")
      .to(".white-slider", { x: "-30%", duration: 1.5 }, "-=1");

    // Fade in the content and move preloader out
    tl.fromTo(
      ".hero-content",
      { opacity: 0, y: -20 },
      { opacity: 1, duration: 0.1, y: 0 },
      "-=1.5"
    )
      .to(".hide", { x: "0%", duration: 0.5, opacity: 1 })
      .to(".preloader", { x: "200%", duration: 1 });

    tl.to(".preloader", {
      duration: 0,
      opacity: 0,
      onComplete: () => {
        document.body.style.overflow = "auto";
      },
    });

    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    initScrollAnimations();
  }, []);

  const firstCardTitle = "Spanning over 20 years";
  const firstCardParagraph =
    "A family-owned agricultural business with a rich history spanning over 20 years. We take immense pride in our heritage and the expertise we have cultivated over the years. Since 1999, our family has been immersed in the world of agriculture, honing our skills and passion for cultivating the finest produce.";
  const firstCardPath = "Onions3_kw55oc.mp4";

  const secondCardTitle = "A trusted name";
  const secondCardParagraph =
    "Our deep-rooted experience and commitment to excellence have shaped Tut Produce into what it is today - a trusted name in the industry. With each passing year, we have refined our farming practices, embraced innovation, and upheld the values that have been passed down through generations.";
  const secondCardPath = "RedOnion_uxqqnf.mp4";
  const secondCardButtonPath = "about";

  const thirdCardTitle = "High Quality Organic Products";
  const thirdCardParagraph =
    "Step into a world where the richness of ancient traditions and modern agricultural excellence converge, presenting you with Tut Produce’s premium selection!";
  const thirdCardPath = "Sweet-potatoes_lbeopg.mp4";
  const thirdCardButtonPath = "products";

  const missionTitle = "Mission";
  const missionParagraph =
    "At Tut Produce, our mission is to leverage our extensive experience in the agriculture sector since 1999 to cultivate and deliver the highest quality agricultural products inspired by the legacy of King Tutankhamun. Our mission spans far beyond the mere cultivation of agricultural products.";
  const missionImage = "bg-home-mission";

  const visionTitle = "Vision";
  const visionParagraph =
    "Our vision at Tut Produce is to be a leading agricultural company, recognized for our unwavering dedication to quality, innovation, and sustainable farming practices. We aspire to become the trusted source for premium produce, not only in our local community but also on a global scale.";
  const visionImage = "bg-home-vision";

  const csrTitle = "Corporate Social Responsibility";
  const csrParagraph =
    "Beyond our commitment to quality, Tut Produce is also dedicated to supporting local communities and fostering sustainable agricultural practices. We work closely with farmers, providing them with fair compensation for their hard work and helping them improve their livelihoods";
  const csrImage = "bg-home-csr";
  return (
    <div>
      <Preloader />
      <div className=" bg-repeat-y bg-background-image bg-contain md:bg-cover">
        <div className=" flex flex-col items-center hero-content overflow-x-hidden ">
          <div className="w-full h-full">
            <div className="relative md:w-full  md:h-full border overflow-hidden m-auto">
              {/* <video
              src={require("../assets/Onions.mp4")}
              alt="hero Onions"
              autoPlay
              loop
              muted
              playsInline
              className="object-cover w-full h-full"
              loading="lazy"
            /> */}

              <video
                src={require("../assets/Onions.mp4")}
                // src="https://res.cloudinary.com/ddoclltn4/video/upload/v1717432512/Onions_v2ltfx.webm"
                alt="hero Onions"
                autoPlay
                loop
                muted
                playsInline
                className="object-cover w-full h-full"
                loading="lazy"
              />

              <div className="flex flex-col absolute inset-0 justify-center items-center z-10 md:mb-52 mb-28">
                <img
                  src={require("../assets/tut-primary.webp")}
                  alt="tut Logo"
                  className="md:w-80 md:h-80 h-28 w-28 mt-20"
                  loading="lazy"
                />
                <h1 className="font-Audiowide text-center  text-white text-xl md:text-3xl lg:text-5xl md:mb-10 ">
                  Welcome to TUT Produce
                </h1>
                <span className="text-center font-bold mt-1 md:mb-2  text-[rgba(255,255,255,0.85)] text-base md:text-3xl lg:text-4xl font-body">
                  Where Ancient Inspiration Meets
                </span>
                <span className="font-body font-bold text-center text-[rgba(255,255,255,0.85)] text-base md:text-3xl lg:text-4xl">
                  Modern Agriculture!
                </span>
              </div>
            </div>
          </div>
          <div className=" md:mx-28 mx-1  flex flex-col text-center w-3/4 md:mt-3 mt-1">
            <div className="z-10">
              <VideoCard2
                videoOrder={1}
                title={firstCardTitle}
                paragraph={firstCardParagraph}
                VideoPath={firstCardPath}
              />
              <VideoCardButton
                videoOrder={2}
                title={secondCardTitle}
                paragraph={secondCardParagraph}
                VideoPath={secondCardPath}
                ButtonPath={secondCardButtonPath}
              />

              <Swiper
                loop={true}
                slidesPerView={3}
                centeredSlides={true}
                // freeMode={true}
                speed={1000}
                spaceBetween={10}
                autoplay={{
                  delay: 1,
                  reverseDirection: true,
                }}
                modules={[Autoplay]}
                className="sample-slider cursor-grab "
              >
                <SwiperSlide>
                  <div class="flex items-start justify-center m-auto ">
                    <img
                      class="lg:mt-3 mt-4 w-14 h-14 lg:w-44 lg:h-44 object-cover"
                      src={require("../assets/CertificeImages/gap.webp")}
                      alt="Red Onions"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="flex items-start justify-center m-auto">
                    <img
                      class="lg:mt-8 mt-4 w-14 h-14 lg:w-36 lg:h-36 object-contain"
                      src={require("../assets/CertificeImages/brc.webp")}
                      alt="Red Onions"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="flex items-center justify-center">
                    <img
                      class="lg:mt-14 mt-4 w-14 h-14 lg:w-28 lg:h-28 object-contain"
                      src={require("../assets/CertificeImages/grasp.webp")}
                      alt="Red Onions"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="flex items-start justify-center">
                    <img
                      class="lg:mt-8 mt-4 w-14 h-14 lg:w-36 lg:h-36 object-contain"
                      src={require("../assets/CertificeImages/iso.webp")}
                      alt="Red Onions"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="flex items-start justify-center">
                    <img
                      class="lg:mt-8 mt-4 w-14 h-14 lg:w-36 lg:h-36 object-contain"
                      src={require("../assets/CertificeImages/sedex.webp")}
                      alt="Red Onions"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="mt-8">
                <h1 className="md:mb-12 mb-4 text-xl md:text-4xl font-Audiowide text-secondary gs_reveal">
                  Our Products
                </h1>
                <VideoCardButton
                  videoOrder={1}
                  title={thirdCardTitle}
                  paragraph={thirdCardParagraph}
                  VideoPath={thirdCardPath}
                  ButtonPath={thirdCardButtonPath}
                />
                {/* <video
                className=" w-full lg:h-[450px] h-[200px] md:w-29 mt-8 rounded-2xl object-cover"
                src="https://res.cloudinary.com/ddoclltn4/video/upload/v1717516913/AboutUsVideo_dsttqj.mp4"
                alt="About Us"
                autoPlay
                loop
                muted
                playsInline
              /> */}
                <VideoCard />
              </div>
              <div className="lg:mt-20 mt-8 ">
                <h1 className="md:mb-12 mb-4 text-xl md:text-4xl font-Audiowide text-secondary gs_reveal">
                  Our Values
                </h1>
                <ValuesCard
                  title={missionTitle}
                  paragraph={missionParagraph}
                  ImageClass={missionImage}
                />
                <ValuesCard
                  title={visionTitle}
                  paragraph={visionParagraph}
                  ImageClass={visionImage}
                />
                <ValuesCard
                  title={csrTitle}
                  paragraph={csrParagraph}
                  ImageClass={csrImage}
                />
              </div>
              <ContactForm />
            </div>
            {/* <div className="z-20  fiexd absolute  bottom-5  right-24">
            <button className=" bottom-20   rounded-lg text-white bg-secondary w-14 h-14">
              ↑
            </button>
          </div> */}
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default Home;
