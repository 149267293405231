import React, { useState } from "react";
import "../Components/Header.css";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  let Links = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Values", link: "/values" },
    { name: "Products", link: "/products" },
  ];

  let [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="shadow-md w-full top-0 left-0">
      <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
        {/* logo section */}
        <div className="w-20 md:w-28">
          <a href="/">
            <img
              src={require("../assets/tut-produce-without-slogan.webp")}
              alt="TUT Produce logo"
              loading="lazy"
            />
          </a>
        </div>

        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
        >
          {open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}
        </div>
        {/* links items */}
        <ul
          className={`header md:flex md:flex-row flex flex-col items-center md:pb-0 pb-6 absolute md:static bg-white md:z-auto z-20 left-0 w-full md:w-auto transition-all duration-300 ease-in ${
            open ? "top-12 justify-center items-center" : "top-[-490px]"
          }`}
        >
          {Links.map((link, index) => (
            <li
              key={index}
              className="md:ml-8 md:my-0 flex md:text-lg text-sm justify-center gap-4 mt-3 font-body relative"
              onClick={() => setOpen(!open)}
            >
              <Link
                to={link.link}
                className={`text-secondary hover:text-tertiary duration-500 focus:text-tertiary ${
                  location.pathname === link.link ? "active" : ""
                }`}
              >
                {link.name}
              </Link>
            </li>
          ))}
          <a href="/contact">
            <button
              className="btn rounded-md bg-secondary px-5 py-1 mt-4 font-body text-white inline-block md:hidden"
              onClick={() => setOpen(!open)}
            >
              Let's Talk
            </button>
          </a>
        </ul>
        <a href="/contact">
          <button
            className="btn rounded-md bg-secondary px-5 py-1 font-body text-white hidden md:inline-block"
            onClick={() => setOpen(!open)}
          >
            Let's Talk
          </button>
        </a>
      </div>
    </div>
  );
};

export default Header;
