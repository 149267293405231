import ImageCard from "../../Components/ImageCard";
import ImageHeader from "../../Components/ImageHeader";
import VideoCard from "../../Components/VideoCard";
function AboutUs() {
  const ImageHeaderTitle = "About Us";
  const ImageHeaderPath = "AboutUsImages/Spring-onions.webp";

  const firstCardTitle = "Legacy in freshness";
  const firstCardParagraph =
    "Tut Produce, a family-owned agricultural business with a rich history spanning over 20 years. We take immense pride in our heritage and the expertise we have cultivated over the years. Since 1999, our family has been immersed in the world of agriculture, honing our skills and passion for cultivating the finest produce";
  const firstCardPath = "AboutUsImages/AboutUs1.webp";

  const secondCardTitle = "Commitment to Excellence";
  const secondCardParagraph =
    "Our deep-rooted experience and commitment to excellence have shaped Tut Produce into what it is today - a trusted name in the industry. With each passing year, we have refined our farming practices, embraced innovation, and upheld the values that have been passed down through generations";
  const secondCardPath = "AboutUsImages/AboutUs2.webp";

  const thirdCardTitle = "Where Freshness meets Flavor";
  const thirdCardParagraph =
    "With a blend of traditional wisdom and modern techniques, we strive to bring you the freshest,  most flavorful agricultural products inspired by the legendary King Tutankhamun. Our family's hands-on approach ensures that each crop is nurtured with care and attention to detail, resulting in produce that exceeds expectations.";
  const thirdCardPath = "AboutUsImages/AboutUs3.webp";

  const fourthCardTitle = "Join Our Family Quality";
  const fourthCardParagraph =
    "When you choose Tut Produce, you are not just choosing a company - you are  joining our family. We invite you to experience the difference that over two decades of expertise  can make in the quality of your produce. Thank you for being a part of our journey";
  const fourthCardPath = "AboutUsImages/AboutUs4.webp";

  return (
    <div className=" bg-repeat-y bg-background-image bg-contain md:bg-cover">
      <div className=" flex flex-col items-center  overflow-x-hidden">
        <ImageHeader
          ImagePath={ImageHeaderPath}
          HeaderImageTitle={ImageHeaderTitle}
        />
        <div className="flex flex-col text-center w-3/4 lg:mt-3 mt-1 mb-3">
          <ImageCard
            imageOrder={1}
            title={firstCardTitle}
            paragraph={firstCardParagraph}
            imagePath={firstCardPath}
          />
          <ImageCard
            imageOrder={2}
            title={secondCardTitle}
            paragraph={secondCardParagraph}
            imagePath={secondCardPath}
          />
          <VideoCard />
          <ImageCard
            imageOrder={1}
            title={thirdCardTitle}
            paragraph={thirdCardParagraph}
            imagePath={thirdCardPath}
          />
          <ImageCard
            imageOrder={2}
            title={fourthCardTitle}
            paragraph={fourthCardParagraph}
            imagePath={fourthCardPath}
          />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
