function ValuesCard({ ImageClass, title, paragraph }) {
  return (
    <div
      className={`flex flex-col text-center m-auto lg:w-5/6 w-full lg:mb-44 mb-14 lg:h-[446px] h-[350px] ${ImageClass} bg-cover rounded-2xl my-8`}
    >
      <div className="bg-white shadow-2xl rounded-2xl lg:w-3/4 w-5/6 lg:h-auto md:h-auto h-5/6  lg:mt-64 md:mt-4 mt-8 lg:my-0 my-8 m-auto justify-center gs_reveal">
        <h1 className="font-Audiowide md:text-3xl text-xl text-secondary capitalize font-normal tracking-wide mt-8">
          {title}
        </h1>
        <p className="font-body md:text-base text-xs text-secondary opacity-90 font-normal lg:tracking-wide tracking-normal lg:mt-4 mt-1 mb-5 lg:w-3/4 w-5/6 m-auto">
          {paragraph}
        </p>
        <a href="/values">
          <button className="bg-secondary text-white inline-flex justify-center items-center gap-2 lg:px-6 px-4 lg:py-2 py-2 lg:rounded-3xl rounded-2xl lg:mt-4 mt-1 hover:scale-105 hover:drop-shadow-lg transition ease-in-out md:mb-8 ">
            <p className="md:text-base text-sm">View More</p>
            <img
              src={require("../assets/arrow-right.webp")}
              className="lg:w-5 w-4 lg:h-5 h-4"
              alt="arrow"
              loading="lazy"
            />
          </button>
        </a>
      </div>
    </div>
  );
}

export default ValuesCard;
