import ImageHeader from "../../Components/ImageHeader";
import ValuesImageCard from "../../Components/ValuesImageCard";
import ValuesAspects from "../../Components/ValuesAspects";
import { initScrollAnimations } from "../../Components/AnimationUtils";
import React, { useEffect } from "react";
function Values() {
  const ImageHeaderTitle = "Our Values";
  const ImageHeaderPath = "ValuesImages/values-header.webp";

  const excellence = "ValuesImages/excellence.webp";
  const sustainability = "ValuesImages/sustainability.webp";
  const groups = "ValuesImages/groups.webp";

  const firstDivParagraph =
    "At Tut Produce, our mission is to leverage our extensive experience in the agriculture sector since 1999 to cultivate and deliver the highest quality agricultural products inspired by the legacy of King Tutankhamun.";
  const secondDivParagraph =
    "We aim to provide our customers with fresh, flavorful, and nutritious produce that surpasses their expectations. By combining our years of expertise with innovative farming practices, we strive to contribute to a healthier, more sustainable food system while honoring the traditions of ancient agriculture.";

  const thirdDivParagraph =
    "Beyond our commitment to quality, Tut Produce is also dedicated to supporting local communities and fostering sustainable agricultural practices. We work closely with farmers, providing them with fair compensation for their hard work and helping them improve their livelihoods. By prioritizing sustainable farming methods, we aim to minimize our environmental impact and contribute to a healthier planet for future generations.";

  const firstCardTitle = "Firm Commitment to Excellence";

  const secondCardTitle = "Sustainability";

  const thirdCardTitle = "Community Support";

  useEffect(() => {
    initScrollAnimations();
  }, []);
  return (
    <div className=" bg-repeat-y bg-background-image bg-contain md:bg-cover">
      <div className="flex flex-col items-center overflow-x-hidden">
        <ImageHeader
          ImagePath={ImageHeaderPath}
          HeaderImageTitle={ImageHeaderTitle}
        />
        <div className=" flex flex-col text-center lg:mt-3 mt-1 lg:mx-28 mx-1 w-3/4">
          <div className="flex flex-col gap-6 lg:gap-12 w-full">
            <h2 className="text-xl md:text-4xl font-Audiowide text-secondary text-start gs_reveal">
              Mission
            </h2>

            <div className="flex flex-row   justify-between gap-8   ">
              <ValuesImageCard imageOrder={1} paragraph={firstDivParagraph} />
            </div>

            <div className=" md:my-0 my-5    flex flex-col items-center lg:flex-row lg:justify-between">
              <div>
                <ValuesAspects
                  ImagePath={excellence}
                  Paragraph={firstCardTitle}
                />
              </div>
              <div className="hidden lg:flex">
                <ValuesAspects
                  ImagePath={sustainability}
                  Paragraph={secondCardTitle}
                />
              </div>
              <div className="hidden lg:flex">
                <ValuesAspects ImagePath={groups} Paragraph={thirdCardTitle} />
              </div>
            </div>

            {/* <div className=" flex flex-col items-center mb-4 text-center lg:hidden">
             <SmallValuesCard></SmallValuesCard>
          </div> */}

            <div className="flex flex-row   justify-between gap-8  drop-shadow-lg">
              <ValuesImageCard
                imageOrder={2}
                paragraph={secondDivParagraph}
              ></ValuesImageCard>
            </div>
          </div>

          <div className="w-full flex flex-col text-start gap-6 mb-10 mt-14">
            <h2 className="md:mb-12 mb-4 text-xl md:text-4xl font-Audiowide text-secondary text-start gs_reveal">
              Vision
            </h2>
            <div className="flex flex-col  gap-6 lg:gap-0 lg:flex-row  ">
              <div className="lg:hidden rounded-xl flex flex-row justify-between gap-4">
                <img
                  src={require("../../assets/ValuesImages/values5.webp")}
                  className="md:w-1/2 w-1/2 items-center m-auto rounded-xl gs_reveal gs_reveal_fromLeft"
                  alt="test1"
                ></img>
                <img
                  src={require("../../assets/ValuesImages/values6.webp")}
                  className="md:w-1/2 w-1/2 items-center m-auto rounded-xl gs_reveal gs_reveal_fromRight"
                  alt="test1"
                ></img>
              </div>

              <div className=" basis-1/2 relative hidden lg:block rounded-xl">
                <img
                  src={require("../../assets/ValuesImages/values6.webp")}
                  className=" w-32 lg:w-60  absolute xl:left-24 lg:left-7 z-10 rounded-xl gs_reveal gs_reveal_fromLeft"
                  alt="test1"
                ></img>

                <img
                  src={require("../../assets/ValuesImages/values5.webp")}
                  className=" w-32 lg:w-60  absolute lg:right-20 lg:top-28 z-20 rounded-xl gs_reveal gs_reveal_fromRight"
                  alt="test1x"
                ></img>
              </div>

              <div className=" basis-1/2 flex flex-col gap-5 lg:gap-10 gs_reveal">
                <h1 className="md:mb-12 mb-1 text-xl md:text-4xl font-Audiowide text-secondary text-center">
                  Tut Produce’s Vision for Sustainable Farming
                </h1>
                <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
                  Our vision at Tut Produce is to be a leading agricultural
                  company, recognized for our unwavering dedication to quality,
                  innovation, and sustainable farming practices. We aspire to
                  become the trusted source for premium produce, not only in our
                  local community but also on a global scale. We envision a
                  future where Tut Produce is synonymous with excellence, and
                  our products are enjoyed by individuals, families, and
                  culinary professionals who appreciate the finest ingredients.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col text-start gap-6 mb-4 lg:mb-10 lg:mt-14">
            <div className="flex flex-col gap-6 lg:gap-0 lg:flex-row ">
              {/* <div className="lg:hidden  ">
              <img
                src={require("../../assets/ValuesImages/Values7.jpg")}
                className="   "
                alt="test1"
              ></img>
            </div> */}

              <div className="lg:order-1 order-2 basis-1/2 flex flex-col gap-5 lg:gap-10 gs_reveal">
                <h1 className="md:mb-12 mb-1 text-xl md:text-4xl font-Audiowide text-secondary text-center">
                  Pushing Boundaries and Inspiring Excellence
                </h1>
                <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
                  By continually pushing the boundaries of agricultural
                  practices and fostering strong relationships with farmers and
                  customers alike, we strive to make a positive impact on the
                  food industry and inspire a love for fresh, flavorful, and
                  responsibly grown produce.
                </p>

                <div className="w-30 flex flex-row justify-center">
                  <a href="/products">
                    <button className="bg-secondary text-white inline-flex justify-center  items-center gap-2 lg:px-6 px-4 lg:py-2 py-2 lg:rounded-3xl rounded-2xl lg:mt-4 mt-1 hover:scale-105 hover:drop-shadow-lg transition ease-in-out md:mb-8">
                      <p className="md:text-base text-sm">
                        Discover Our Products
                      </p>
                      <img
                        src={require("../../assets/arrow-right.webp")}
                        className="lg:w-5 w-4 lg:h-5 h-4"
                        alt="arrow"
                      />
                    </button>
                  </a>
                </div>
              </div>
              <div className="lg:hidden rounded-xl flex flex-row justify-between gap-4">
                <img
                  src={require("../../assets/ValuesImages/values7.webp")}
                  className="md:w-1/2 w-1/2 items-center m-auto rounded-xl gs_reveal gs_reveal_fromLeft"
                  alt="test1"
                ></img>
                <img
                  src={require("../../assets/ValuesImages/values8.webp")}
                  className="md:w-1/2 w-1/2 items-center m-auto rounded-xl gs_reveal gs_reveal_fromRight"
                  alt="test1"
                ></img>
              </div>

              <div className="lg:order-2 order-1 basis-1/2 relative hidden lg:block rounded-xl">
                <img
                  src={require("../../assets/ValuesImages/values7.webp")}
                  className=" w-32 lg:w-60  absolute xl:left-24 lg:left-7 z-10 rounded-xl gs_reveal gs_reveal_fromLeft"
                  alt="test1"
                ></img>

                <img
                  src={require("../../assets/ValuesImages/values8.webp")}
                  className=" w-32 lg:w-60  absolute lg:right-20 lg:top-28 z-20 rounded-xl gs_reveal gs_reveal_fromRight"
                  alt="test1x"
                ></img>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 mb-8">
            <h1 className="md:mt-12 mt-4 text-xl md:text-4xl font-Audiowide text-secondary text-start gs_reveal">
              Corporate Social Responsibility
            </h1>
            <div>
              <ValuesImageCard
                imageOrder={1}
                paragraph={thirdDivParagraph}
              ></ValuesImageCard>
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* <Footer></Footer> */}
      </div>
    </div>
  );
}
export default Values;
