function ValuesImageCard({ imageOrder, imagePath, paragraph }) {
  let orderImage = "";
  let orderText = "";
  let align = "";
  if (imageOrder === 1) {
    orderImage = "order-1";
    orderText = "order-2";
    align = "start";
  } else if (imageOrder === 2) {
    orderImage = "order-2";
    orderText = "order-1";
    align = "end";
  }

  return (
    <div className="flex flex-col  lg:flex-row md:justify-between md:items-between mb-3 md:mb-8  ">
      <div
        className={`basis-1/2 start-10 flex flex-row mb-3 justify-${align} md:mb-0 order-1   md:${orderImage} gs_reveal gs_reveal_fromLeft`}
      >
        <img
          className=" lg:w-2/3 w-full h-full mx-auto object-cover  rounded-2xl lg:my-8"
          src={require("../assets/ValuesImages/values-field.webp")}
          alt="About Us"
        />
      </div>

      <div
        className={`text-center basis-1/2 my-auto order-2 md:${orderText} gs_reveal gs_reveal_fromRight`}
      >
        <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
          {paragraph}
        </p>
      </div>
    </div>
  );
}

export default ValuesImageCard;
