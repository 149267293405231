import React from "react";
import "./Preloader.css"; // Import CSS for styling if needed

const Preloader = () => {
  return (
    <div className="preloader overflow-hidden ">
      <div className="loading-page md:gap-6 gap-3">
        <svg
          id="svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="2400"
          height="2400.188"
          colorInterpolationFilters="sRGB"
          version="1"
          viewBox="144.296 99.294 51.408 51.412"
        >
          <defs>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#fa71cd"></stop>
              <stop offset="100%" stopColor="#9b59b6"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#f9d423"></stop>
              <stop offset="100%" stopColor="#f83600"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#0064d2"></stop>
              <stop offset="100%" stopColor="#1cb0f6"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#f00978"></stop>
              <stop offset="100%" stopColor="#3f51b1"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#7873f5"></stop>
              <stop offset="100%" stopColor="#ec77ab"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#f9d423"></stop>
              <stop offset="100%" stopColor="#e14fad"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#009efd"></stop>
              <stop offset="100%" stopColor="#2af598"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#fc0"></stop>
              <stop offset="100%" stopColor="#00b140"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#d51007"></stop>
              <stop offset="100%" stopColor="#ff8177"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#a2b6df"></stop>
              <stop offset="100%" stopColor="#0c3483"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#7ac5d8"></stop>
              <stop offset="100%" stopColor="#eea2a2"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#00ecbc"></stop>
              <stop offset="100%" stopColor="#007adf"></stop>
            </linearGradient>
            <linearGradient x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="#b88746"></stop>
              <stop offset="100%" stopColor="#fdf5a6"></stop>
            </linearGradient>
          </defs>
          <g transform="translate(144.8 99.798)" className="0">
            <svg
              width="50.4"
              height="50.404"
              className="image-svg-svg primary"
              filter="url(#colors1815163014)"
              overflow="visible"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="10 9.993 80 80.007"
              >
                <path d="M78.987 69.222l-9.624-1.877c.235-.585.47-1.202.706-1.858 3.59-9.99 12.7-12.341 12.791-12.364a1.001 1.001 0 00.714-1.281l-8.07-24.74c-.035-.128-3.634-12.521-19.162-13.647l.101-1.064a1 1 0 00-.515-.973c-.238-.129-5.87-3.134-11.84-.008a.998.998 0 00-.531.98l.101 1.065c-15.526 1.127-19.125 13.518-19.146 13.601l-8.085 24.7a.998.998 0 00.707 1.28c.09.023 9.193 2.415 12.799 12.45.233.65.465 1.26.698 1.84l-9.638 1.9c-7.083 1.52-9.52 7.867-10.343 11.516-.41 1.825-.641 5.966-.65 8.254A.999.999 0 0011 90h78a.999.999 0 001-1.004c-.009-2.288-.24-6.43-.65-8.254-.823-3.649-3.26-9.995-10.363-11.52zm2.37-17.726c-2.697.944-9.912 4.245-13.17 13.314-3.475 9.671-6.604 10.38-10.73 10.433V61.49a16.053 16.053 0 003.867-2.942c8.384-8.586 8.95-26.016 8.962-30.18h3.528l7.543 23.128zm-25.9 16.2c-1.62.572-3.565.896-5.562.896-1.932 0-3.762-.29-5.348-.82v-5.403c1.695.613 3.511.94 5.454.94 1.943 0 3.76-.327 5.455-.94v5.327zM45.084 28.367l.105 1.104a1 1 0 00.996.905h7.628a1 1 0 00.996-.905l.105-1.104h13.366c-.03 3.94-.626 20.835-8.387 28.783C57.199 59.91 53.87 61.31 50 61.31c-3.87 0-7.196-1.399-9.89-4.156-7.757-7.94-8.358-24.844-8.39-28.786h13.364zm28.018-2H55.106l1.045-10.922c11.374.8 15.649 7.917 16.952 10.922zM54.385 12.908l-1.48 15.468h-5.81l-1.482-15.484c3.782-1.68 7.398-.547 8.772.016zm-10.536 2.538l1.045 10.921H26.912c1.316-2.954 5.656-10.12 16.937-10.921zM18.642 51.418l7.543-23.05h3.531c.013 4.164.578 21.594 8.962 30.18a16.05 16.05 0 003.869 2.942v13.753c-4.127-.053-7.257-.762-10.732-10.433a22.029 22.029 0 00-13.173-13.392zM12.015 88c.06-2.41.287-5.49.587-6.819.717-3.182 2.816-8.713 8.794-9.996l10.044-1.98c3.444 7.437 7.01 8.042 12.107 8.042a1 1 0 001-1v-6.38c1.635.468 3.453.725 5.348.725 1.984 0 3.875-.28 5.561-.79v6.445a1 1 0 001 1c5.091 0 8.655-.604 12.097-8.023l10.033 1.957c5.996 1.287 8.095 6.818 8.812 10 .3 1.33.528 4.41.587 6.819h-75.97z"></path>
                <path d="M50 54.12c2.64 0 5.188-1.156 6.993-3.169a1 1 0 00-1.49-1.335c-1.446 1.614-3.4 2.503-5.503 2.503s-4.057-.889-5.503-2.503a1 1 0 10-1.49 1.335C44.812 52.964 47.36 54.12 50 54.12z"></path>
              </svg>
            </svg>
            <defs>
              <filter id="colors1815163014">
                <feColorMatrix
                  className="icon-feColorMatrix"
                  values="0 0 0 0 0.1484375 0 0 0 0 0.0703125 0 0 0 0 0.35546875 0 0 0 1 0"
                ></feColorMatrix>
              </filter>
              <filter>
                <feColorMatrix
                  className="icon-fecolormatrix"
                  values="0 0 0 0 0.99609375 0 0 0 0 0.99609375 0 0 0 0 0.99609375 0 0 0 1 0"
                ></feColorMatrix>
              </filter>
              <filter>
                <feColorMatrix
                  className="icon-fecolormatrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                ></feColorMatrix>
              </filter>
            </defs>
          </g>
        </svg>

        <div className="name-container">
          <div className="logo-name font-Audiowide text-secondary lg:text-5xl md:text-2xl text-xl">
            TUT PRODUCE
          </div>
        </div>
      </div>
      <div class="lightCyan-slider"></div>
      <div class="persianGreen-slider"></div>
      <div class="white-slider loading-page">
        <h2 className="text-secondary font-Audiowide lg:text-4xl md:text-xl text-lg text-center lg:ml-48 md:ml-36 ml-40 lg:mr-5 md:mr-20 md:pl-48">
          Where Ancient Traditions Meets <br /> Modern Agriculture!
        </h2>
      </div>
    </div>
  );
};

export default Preloader;
