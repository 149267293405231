import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";

function ContactForm() {
  const form = useRef();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [isFormFailure, setFormFailure] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_oezq8ig", "template_hc9ezvr", form.current, {
        publicKey: "cZDVkMI6TMjSE17Jm",
      })
      .then(
        () => {
          setModalVisible(true);
          setFormSuccess(true);
          form.current.reset();
        },
        (error) => {
          setModalVisible(true);
          setFormFailure(true);
          form.current.reset();
        }
      );
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="flex flex-col text-center m-auto md:w-5/6 w-full mt-3 md:h-[546px] h-full bg-field-contact rounded-2xl my-8 gs_reveal gs_reveal_fromRight">
      <div className="bd backdrop-blur-lg rounded-2xl md:w-5/6 w-5/6 lg:h-[470px] md:h-[500px]  h-5/6 md:mt-8 mt-8 md:my-0 my-8 m-auto justify-center">
        <h1 className="font-Audiowide md:text-3xl text-xl text-secondary capitalize font-normal tracking-wide mt-8">
          Get in touch
        </h1>
        <p className="font-body md:text-base text-xs text-secondary opacity-90 font-normal tracking-wide  md:mt-4 mt-1 mb-7 w-3/4 m-auto">
          For further questions, including partnership opportunities, <br />
          please email info@tutproduce.com or contact using our contact form.
        </p>
        <form ref={form} onSubmit={sendEmail} className="w-full">
          <div className="w-3/4 mt-1 grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-6 m-auto h-full">
            <div className="md:col-span-3 col-span-2">
              <label
                htmlFor="full-name"
                className="block text-sm font-medium leading-6 text-gray-900 text-left"
              >
                
              </label>
              <div className="md:mt-2 mt-px -ml-px">
                <input
                  type="text"
                  name="user_name"
                  id="full-name"
                  autoComplete="given-name"
                  placeholder="Full name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-md text-xs sm:leading-6"
                />
              </div>
            </div>

            <div className="md:col-span-3 col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 text-left"
              >
                
              </label>
              <div className="md:mt-2 mt-px -ml-px">
                <input
                  type="email"
                  name="user_email"
                  id="email"
                  placeholder="info@tutproduce.com"
                  required
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-md text-xs sm:leading-6"
                />
              </div>
            </div>

            <div className="lg:col-span-full  md:col-span-full col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900 text-left"
              >
               
              </label>
              <div className="md:mt-1 mt-px">
                <textarea
                  name="message"
                  id="message"
                  autoComplete="message"
                  placeholder="Message"
                  required
                  className="block w-full lg:h-32 md:h-28 h-20 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-md text-xs sm:leading-6"
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            value="Send"
            className="md:my-4 my-2 md:col-span-full text-primary bg-secondary hover:scale-105 transition ease-in-out hover:drop-shadow-lg focus:ring-4 focus:outline-none focus:ring-tertiary font-medium rounded-lg text-sm lg:w-32 w-24 px-5 lg:py-2.5 py-1.5 text-center"
          >
            Submit
          </button>
        </form>
        {isModalVisible && isFormSuccess && (
          <div
            id="successModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex justify-center items-center lg:mt-0 mt-32"
          >
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
                <button
                  id="successButton"
                  type="button"
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={closeModal}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="w-12 h-12 rounded-full bg-secondary p-2 flex items-center justify-center mx-auto mb-3.5">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Success</span>
                </div>
                <p className="mb-4 text-lg font-semibold text-gray-900">
                  Successfully sent the message.
                </p>
                <button
                  type="button"
                  onClick={closeModal}
                  className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-secondary  focus:ring-4 focus:outline-none focus:ring-tertiary"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
        {isModalVisible && isFormFailure && (
          <div
            id="failureModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex justify-center items-center lg:mt-0 mt-32"
          >
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
              <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5">
                <button
                  id="failureButton"
                  type="button"
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={closeModal}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="w-12 h-12 rounded-full bg-red-100 p-2 flex items-center justify-center mx-auto mb-3.5">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-red-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Failure</span>
                </div>
                <p className="mb-4 text-lg font-semibold text-gray-900">
                  Couldn't send message! Please send a direct email to
                  info@tutproduce.com
                </p>
                <button
                  type="button"
                  onClick={closeModal}
                  className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-secondary  focus:ring-4 focus:outline-none focus:ring-tertiary"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
