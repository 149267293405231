import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
function ValuesAspects({ ImagePath, Paragraph }) {
  const textRef = useRef();

  useEffect(() => {
    const typed = new Typed(textRef.current, {
      strings: [
        "Firm Commitment to Excellence",
        "Sustainability",
        "Community Support",
      ],
      loop: true,
      startDelay: 1000,
      showCursor: true,
      typeSpeed: 60,
      backSpeed: 60,
      backDelay: 1500,
      onComplete: (self) => {
        self.cursor.remove();
      },
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className="bg-gray-100 w-56 md:w-80 pb-8  lg:w-64 h-auto flex  flex-col justify-start items-center pt-10 rounded-lg shadow-md">
      <div className=" w-36 bg-secondary h-36 flex justify-center items-center mb-8  rounded-full ">
        <img
          src={require(`../assets/${ImagePath}`)} // Replace with your image path
          alt="Card Image 1"
          className="w-1/2 "
        />
      </div>

      <p className="hidden lg:flex text-sm md:text-base font-normal mb-10 text-secondary">
        {Paragraph}
      </p>
      <div className=" lg:hidden h-10">
        <h1 className=" brand">
          <span
            className="text-secondary text-sm md:text-base  font-semibold"
            ref={textRef}
          ></span>
        </h1>
      </div>
    </div>
  );
}
export default ValuesAspects;
