import "./ImageCard.css";
import React, { useEffect } from "react";
import { initScrollAnimations } from "../Components/AnimationUtils";

function ImageCard({ imageOrder, imagePath, title, paragraph }) {
  let orderImage = "";
  let orderText = "";

  if (imageOrder === 1) {
    orderImage = "order-1";
    orderText = "order-2";
  } else if (imageOrder === 2) {
    orderImage = "order-2";
    orderText = "order-1";
  }

  useEffect(() => {
    initScrollAnimations();
  }, []);

  return (
    <div>
      <div className="flex flex-col  md:flex-row md:justify-between md:items-between mb-3 md:mb-20   ">
        <div
          className={`basis-1/2 px-8 mb-3 md:mb-0 order-1 md:${orderImage} gs_reveal gs_reveal_fromLeft`}
        >
          <img
            className=" w-full h-full md:w-29 mx-auto rounded-xl"
            src={require(`../assets/${imagePath}`)}
            alt="About Us"
            loading="lazy"
          />
        </div>

        <div
          className={`text-center basis-1/2 my-auto order-2 md:${orderText} gs_reveal gs_reveal_fromRight`}
        >
          <h1 className="md:mb-4 mb-3 text-xl md:text-3xl font-Audiowide text-secondary">
            {title}
          </h1>

          <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
            {paragraph}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ImageCard;
