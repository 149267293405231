function VideoCardButton({
  videoOrder,
  VideoPath,
  title,
  paragraph,
  ButtonPath,
}) {
  let orderVideo = "";
  let orderText = "";

  if (videoOrder === 1) {
    orderVideo = "order-1";
    orderText = "order-2";
  } else if (videoOrder === 2) {
    orderVideo = "order-2";
    orderText = "order-1";
  }

  return (
    <div className=" mx-0 md:mt-20 mt-10">
      <div className="flex flex-col  lg:flex-row lg:justify-between lg:items-between mb-3 lg:mb-8 ">
        <div
          className={`basis-1/2 px-8 mb-3 lg:mb-0 order-2 lg:${orderVideo} gs_reveal gs_reveal_fromLeft`}
        >
          <video
            className=" w-screen h-full md:w-29 mx-auto overflow-hidden rounded-xl lg:rounded-2xl object-cover"
            src={`https://res.cloudinary.com/ddoclltn4/video/upload/${VideoPath}`}
            alt="About Us"
            autoPlay
            loop
            muted
            playsInline
            loading="lazy"
          />
        </div>

        <div
          className={`text-center basis-1/2 my-auto order-2 md:${orderText} gs_reveal gs_reveal_fromRight`}
        >
          <h1 className="md:mb-12 mb-4 text-xl md:text-4xl font-Audiowide text-secondary">
            {title}
          </h1>

          <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
            {paragraph}
          </p>
          <a href={ButtonPath}>
            <button className="bg-secondary text-white inline-flex justify-center items-center gap-2 md:px-6 px-4 md:py-2 py-2 md:rounded-3xl rounded-2xl lg:mt-14 md:mt-8 mt-4 hover:scale-105 hover:drop-shadow-lg transition ease-in-out">
              <p className="md:text-base text-sm">Know More</p>
              <img
                src={require("../assets/arrow-right.webp")}
                className="md:w-5 w-4 md:h-5 h-4"
                alt="arrow"
                loading="lazy"
              />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoCardButton;
