import "./VideoCard.css";

function VideoCard() {
  return (
    <div className="flex flex-col  justify-center text-center mb-8 w-full h-full gs_reveal">
      <div className="mb-2 lg:order-1 order-2">
        <h1 className="md:mb-4 mb-2 text-xl md:text-3xl font-Audiowide text-secondary">
          Your Satisfaction is our passion
        </h1>

        <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
          As a family-owned business, we hold ourselves accountable for the
          quality of our products and the satisfaction of our customers. We
          believe in the power of personal connections, and every member of our
          team is dedicated to providing exceptional service and support.
        </p>
      </div>

      <video
        className=" w-full lg:h-[450px] md:h-[200px] h-[100px] md:w-29 mt-8 mb-8 rounded-2xl object-cover lg:order-2 order-1"
        src="https://res.cloudinary.com/ddoclltn4/video/upload/v1717516913/AboutUsVideo_dsttqj.mp4"
        alt="About Us"
        autoPlay
        loop
        muted
        playsInline
      />
    </div>
  );
}

export default VideoCard;
