import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./Contact.css";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageHeader from "../../Components/ImageHeader";
import { initScrollAnimations } from "../../Components/AnimationUtils";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ContactForm from "../../Components/ContactForm";

function Contact() {
  // Define media queries for desktop and mobile/tablet screens
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const ImageHeaderTitle = "Contact us";
  const ImageHeaderPath = "ContactImages/onions4.webp";

  useEffect(() => {
    initScrollAnimations();
  }, []);

  return (
    <div className="  flex flex-col items-center  overflow-x-hidden bg-background-image bg-cover bg-repeat-y">
      <ImageHeader
        ImagePath={ImageHeaderPath}
        HeaderImageTitle={ImageHeaderTitle}
      />
      <div className=" flex justify-center items-center w-full h-full ">
        <div className=" flex flex-col text-center w-3/4 lg:mt-3 mt-1">
          <div className="flex flex-col text-center m-auto mt-3 justify-center">
            <div className="flex lg:flex-row flex-col md:w-full w-72 lg:h-96  h-full lg:bg-transparent  bg-gray-50 rounded-3xl p-1">
              <div className="flex-auto lg:w-1/3 w-full lg:h-full h-32 gs_reveal gs_reveal_fromLeft">
                <img
                  className="rounded-3xl object-cover h-full w-full"
                  src={require("../../assets/onions-basket.webp")}
                  alt="Onions"
                />
              </div>
              <div className="flex-auto lg:w-2/3 w-full items center gs_reveal gs_reveal_fromRight">
                <h1 className="font-Audiowide md:text-4xl text-2xl text-secondary capitalize font-normal tracking-wide lg:mt-20 mt-10 w-full">
                  Let's Get in Touch
                </h1>
                <p className="lg:my-8 my-4 lg:mx-20 mx-10 md:text-lg text-base text-center font-normal">
                  Please contact us using the information below. Follow us on
                  Facebook and LinkedIn to always be in touch.
                </p>
                <div className="flex flex-row items-center m-2 gap-8">
                  {/* <div className="flex flex-row flex-auto h-9 lg:ml-20 lg:mr-16 lg:h-full md:h-11 lg:rounded-2xl rounded-lg border-gray-200 border-solid border border-opacity-90 font-body text-xl bg-white transition-colors duration-300 ease-linear hover:bg-gray-100 cursor-pointer"> */}
                  <a
                    href="https://www.facebook.com/share/aoReKeGEmKSqAFg3/?mibextid=LQQJ4d"
                    target="_blank"
                    className="flex flex-row flex-auto h-9  md:ml-16 lg:h-full md:h-11 lg:rounded-2xl rounded-lg border-gray-200 border-solid border border-opacity-90 font-body text-xl bg-white transition-colors duration-300 ease-linear hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="lg:w-20 md:w-11 w-9 lg:h-20 bg-gray-100 rounded-lg bg-cover flex justify-center items-center">
                      <img
                        className="w-2/3 h-2/3"
                        src={require("../../assets/facebook.webp")}
                        alt="Facebook"
                      />
                    </div>

                    <p className="flex flex-auto justify-center items-center md:text-xl text-base font-body md:px-0 px-2">
                      Facebook
                    </p>
                  </a>
                  {/* </div> */}

                  {/* <div className="flex flex-row flex-auto h-9  md:ml-16 lg:h-full md:h-11 lg:rounded-2xl rounded-lg border-gray-200 border-solid border border-opacity-90 font-body text-xl bg-white transition-colors duration-300 ease-linear hover:bg-gray-100 cursor-pointer"> */}
                  <a
                    href="https://www.linkedin.com/company/tutproduce/"
                    target="_blank"
                    className="flex flex-row flex-auto h-9  md:ml-16 lg:h-full md:h-11 lg:rounded-2xl rounded-lg border-gray-200 border-solid border border-opacity-90 font-body text-xl bg-white transition-colors duration-300 ease-linear hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="lg:w-20 md:w-11 w-9 lg:h-20 bg-gray-100 rounded-lg bg-cover flex justify-center items-center">
                      <img
                        className="lg:w-full w-full h-full bg-cover"
                        src={require("../../assets/linkedin.webp")}
                        alt="Linkedin"
                      />
                    </div>
                    <p className="flex flex-auto justify-center items-center md:text-xl text-base font-body md:px-0 px-2">
                      Linkedin
                    </p>
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* margin was 4 not 8  */}
          {/* lg:w-5/6 */}
          <div className="flex flex-row text-center m-auto lg:w-1/2 md:w-3/4 w-full mt-8 lg:h-96 h-64  rounded-2xl shadow-2xl border-gray-200 border-solid border border-opacity-90 my-8 items-center gs_reveal gs_reveal_fromLeft">
            {/* lg:w-1/2 */}
            <div className="flex flex-col text-center lg:w-full w-full h-full mx-8 m-auto">
              <h1 className="font-Audiowide md:text-3xl text-xl text-secondary capitalize font-normal tracking-wide mt-8 ">
                Join our fast <br />
                growing team
              </h1>
              <p className="font-body md:text-md text-sm text-secondary opacity-90 capitalize font-normal tracking-wide lg:mt-11 mt-4 mb-5">
                Want to have an opportunity to be part of out family? <br />
                Send your CV to the below email.
              </p>
              <div className="flex flex-row md:w-auto w-52 lg:h-16 md:h-20 h-12 lg:rounded-xl rounded-lg border-gray-200 border-solid border border-opacity-90 font-body text-xl bg-white transition-colors duration-300 ease-linear hover:bg-gray-100 cursor-pointer mx-auto lg:mt-10 mb-8">
                <div className="lg:w-16 w-9 lg:h-16 bg-gray-100 lg:rounded-xl rounded-sm bg-cover flex justify-center items-center mx-auto">
                  <img
                    className="lg:w-1/2 w-2/3 lg:h-1/2 h-2/3 m-auto"
                    src={require("../../assets/mail.webp")}
                    alt="Mail"
                  />
                </div>
                <p className="flex flex-auto justify-center items-center md:text-xl text-base font-body md:px-2">
                  careers@tutproduce.com
                </p>
              </div>
            </div>
            {/* remove the comment  */}
            {/* <div className="lg:w-1/2 w-full h-full mx-8 m-auto lg:inline-block hidden">
            <div class="grid grid-cols-2 gap-6 md:grid-cols-3 h-full">
              <div class="grid gap-8">
                <div>
                  <img
                    class="h-auto max-w-full rounded-lg object-cover object-center mt-5"
                    src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
                    alt="gallery-photo"
                  />
                </div>
                <div>
                  <img
                    class="h-3/4 max-w-full rounded-lg object-cover object-center mb-5 m-auto"
                    src="https://images.unsplash.com/photo-1629367494173-c78a56567877?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=927&amp;q=80"
                    alt="gallery-photo"
                  />
                </div>
              </div>
              <div class="grid">
                <div>
                  <img
                    class="h-3/4 max-w-full rounded-lg object-cover object-center mt-5 m-auto"
                    src="https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=687&amp;q=80"
                    alt="gallery-photo"
                  />
                </div>
                <div>
                  <img
                    class="h-3/4 max-w-full rounded-lg object-cover object-center mb-5 m-auto"
                    src="https://docs.material-tailwind.com/img/team-3.jpg"
                    alt="gallery-photo"
                  />
                </div>
              </div>
              <div class="grid gap-8">
                <div>
                  <img
                    class="h-auto max-w-full rounded-lg object-cover object-center mt-5"
                    src="https://docs.material-tailwind.com/img/team-3.jpg"
                    alt="gallery-photo"
                  />
                </div>
                <div>
                  <img
                    class="h-3/4 max-w-full rounded-lg object-cover object-center mb-5 m-auto"
                    src="https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=687&amp;q=80"
                    alt="gallery-photo"
                  />
                </div>
              </div>
            </div>
          </div> */}
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
