import "./Footer.css";
// import "../Fonts/CustomFonts.css";
function Footer() {
  return (
    <div className=" bg-gray-100 w-full bottom-0 right-0 md:px-10  py-9 md:py-20">
      <div className=" md:hidden flex justify-center items-center  mb-3 ">
        <img
          className="w-32"
          src={require("../assets/tut-produce-without-slogan.webp")}
          alt="TUT Produce logo"
          loading="lazy"
        />
      </div>

      <div className=" flex md:flex-row md:justify-between flex-col items-center justify-center">
        <div className="order-2 md:order-1 ">
          <div className=" hidden md:flex md:justify-center md:items-center ">
            <a href="/">
              <img
                className="w-32"
                src={require("../assets/tut-produce-without-slogan.webp")}
                alt="TUT Produce logo"
                loading="lazy"
              />
            </a>
          </div>
          <div className=" order-1 md:order-2">
            <p className="  text-sm text-slate-500  mt-1">
              @2024, All Rights Reserved
            </p>
          </div>
        </div>

        <div className="order-1 ">
          <div className=" hidden md:order-1 md:flex md:justify-center md:items-center md:gap-7 md:mb-3">
            <a
              className="text-gray-700 hover:text-blue-600"
              aria-label="Visit TrendyMinds Facebook"
              href="https://www.facebook.com/share/aoReKeGEmKSqAFg3/?mibextid=LQQJ4d"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="h-6"
              >
                <path
                  fill="currentColor"
                  d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/tutproduce/"
              aria-label="Find us on LinkedIn"
              target="_blank"
              rel="noopener"
            >
              <svg
                className="hover:text-blue-900 h-7 w-7 text-gray-700"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.45 0H3.55A3.5 3.5 0 0 0 0 3.46v41.07A3.5 3.5 0 0 0 3.54 48h40.9A3.51 3.51 0 0 0 48 44.54V3.46A3.5 3.5 0 0 0 44.45 0Zm-30.2 40.9H7.11V18h7.12v22.9Zm-3.57-26.03a4.13 4.13 0 1 1-.02-8.26 4.13 4.13 0 0 1 .02 8.26ZM40.9 40.9H33.8V29.77c0-2.66-.05-6.08-3.7-6.08-3.7 0-4.27 2.9-4.27 5.89V40.9h-7.1V18h6.82v3.12h.1c.94-1.8 3.26-3.7 6.72-3.7 7.21 0 8.54 4.74 8.54 10.91V40.9Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </div>
          <div className="order-1 text-center md:order-2  ">
            <ul className="flex flex-row w-full md:justify-center md:items-center md:gap-7 font-body font-bold  text-center justify-center gap-4">
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/products">Products</a>
              </li>
              <li>
                <a href="/values">Values</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
            <hr class="my-3 sm:mx-auto border-gray-300 lg:my-8 md:hidden" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
