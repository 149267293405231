import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageHeader from "../../Components/ImageHeader";
import { initScrollAnimations } from "../../Components/AnimationUtils";

import redOnionImage from "../../assets/redonion.webp";
import goldenOnionImage from "../../assets/golden-onions.webp";
import springOnionImage from "../../assets/spring-onions.webp";
import sweetPotatoImage from "../../assets/sweet-potato.webp";

import redOnionCalendar from "../../assets/red-onions-calendar.png";
import goldenOnionCalendar from "../../assets/golden-onions-calendar.png";
import springOnionCalendar from "../../assets/spring-onions-calendar.png";
import sweetPotatoCalendar from "../../assets/sweet-potatoes-calendar.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./Products.css";
import Modal from "../../Components/Modal";

const Product = ({ name, image, onClick }) => (
  <div className="max-w-md mx-auto bg-white md:max-w-2xl md:h-96 h-72 md:w-72 w-72 shadow-lg group relative cursor-default items-center justify-center overflow-hidden rounded-xl hover:shadow-xl hover:scale-105 duration-500 transform">
    <div className="flex items-start justify-center">
      <img
        className="w-1/2 h-1/2 mt-8 md:w-48 md:h-48 object-cover transition-transform duration-500 group-hover:rotate-2 group-hover:scale-110"
        src={image}
        alt={name}
        loading="lazy"
      />
    </div>
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent group-hover:from-black/70 group-hover:via-black/20 group-hover:to-black/20"></div>
    <div className="absolute inset-0 flex translate-y-[40%] flex-col items-center justify-center px-6 md:px-9 text-center transition-all duration-500 group-hover:translate-y-0">
      <h1 className="font-dmserif text-xl md:text-3xl font-bold text-secondary opacity-100 transition-opacity duration-300 group-hover:opacity-0">
        {name}
      </h1>
      <button
        onClick={onClick}
        className="bg-secondary text-white inline-flex justify-center items-center gap-2 md:px-6 px-4 md:py-2 py-2 md:rounded-3xl rounded-2xl lg:mt-14 md:mt-8 mt-4 hover:scale-105 hover:drop-shadow-lg transition ease-in-out opacity-0 group-hover:opacity-100"
      >
        <p className="md:text-base text-sm">View Details</p>
        <img
          src={require("../../assets/arrow-right.webp")}
          className="md:w-5 w-4 md:h-5 h-4"
          alt="arrow"
          loading="lazy"
        />
      </button>
    </div>
  </div>
);

function Products() {
  const ImageHeaderTitle = "Products";
  const ImageHeaderPath = "ProductsImages/products.webp";

  useEffect(() => {
    initScrollAnimations();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = [
    {
      name: "Red Onions",
      image: redOnionImage,
      calendar: redOnionCalendar,
      paragraph: [
        <strong>Calibers in mm: </strong>,
        "40/60, 60/80, 80/100 & 100+ ",
        <br key="1" />,
        <strong>Or: </strong>,
        "50/70,60/80, 70/90 & 90+",
        <br key="1" />,
        <strong>Or: </strong>,
        "40/50, 50/60, 60/70, 70/80 & 80+",
        <br key="1" />,
        <strong>Or: </strong>,
        "50/65, 65/75, 75/85 & 85+",
        <hr key="1" />,
        <strong>Packaging: </strong>,
        "5 kg, 10 Kg, 15 kg, 20 kg, 25 kg on 18 wooden Pallets – container Net weight, up to 27,500 kg & 1250 KG on 20 wooden Pallets Container Net weight 25,000 or 22 Wooden Pallet Net weight is 27,500 kg",
      ],
    },
    {
      name: "Golden Onions",
      image: goldenOnionImage,
      calendar: goldenOnionCalendar,
      paragraph: [
        <strong>Calibers in mm: </strong>,
        "40/60, 60/80, 80/100 & 100+ ",
        <br key="1" />,
        <strong>Or: </strong>,
        "50/70,60/80, 70/90 & 90+",
        <br key="1" />,
        <strong>Or: </strong>,
        "40/50, 50/60, 60/70, 70/80 & 80+",
        <br key="1" />,
        <strong>Or: </strong>,
        "50/65, 65/75, 75/85 & 85+",
        <hr key="1" />,
        <strong>Packaging: </strong>,
        "5 kg, 10 Kg, 15 kg, 20 kg, 25 kg on 18 wooden Pallets – container Net weight, up to 27,500 kg & 1250 KG on 20 wooden Pallets Container Net weight 25,000 or 22 Wooden Pallet Net weight is 27,500 kg",
      ],
    },
    {
      name: "Spring Onions",
      image: springOnionImage,
      calendar: springOnionCalendar,
      paragraph: [
        <strong>Length: </strong>,
        "280-300 mm",
        <hr key="1" />,
        <strong>Bulb size: </strong>,
        "9-15 mm",
        <hr key="1" />,
        <strong>Bunch weight: </strong>,
        "100g-125g",
        <hr key="1" />,
        <strong>Bunched: </strong>,
        "7-11 pieces",
        <hr key="1" />,
        <strong>Variety Name: </strong>,
        "Photon",
        <hr key="1" />,
        <strong>Color: </strong>,
        "White bulb",
        <hr key="1" />,
        <strong>Quality grade: </strong>,
        "Class 1",
      ],
    },
    {
      name: "Sweet Potatoes",
      image: sweetPotatoImage,
      calendar: sweetPotatoCalendar,
      paragraph: [
        <strong>Size in grams/pc: </strong>,
        "S 80/150 ,M 150/300 , L1 300/450 , L2 450/600 , XL 600/800 & XXL 800+ ",
        <hr key="1" />,
        <strong>Packaging: </strong>,
        "6 kg Carton box – 3600 Carton Box on 20 wooden pallets  or 600 Kg Bins – 40 wooden pallets",
        <hr key="1" />,
        "Will be 2 rows in the containers.",
      ],
    },
  ];

  const openModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  return (
    <div className=" bg-repeat-y bg-background-image bg-cover">
      <div className=" flex flex-col items-center overflow-x-hidden">
        <ImageHeader
          ImagePath={ImageHeaderPath}
          HeaderImageTitle={ImageHeaderTitle}
        />
        <div className=" md:mx-28 mx-1  flex flex-col text-center w-3/4 md:mt-3 mt-1">
          <h1 className="md:text-3xl text-xl header md:font-light font-extrabold text-center text-secondary font-Audiowide md:pt-14 pt-2 gs_reveal">
            Discover our Premium selection <br />
            of Fresh Produce!
          </h1>
          <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal gs_reveal">
            Step into a world where the richness of ancient traditions and
            modern agricultural excellence converge, presenting you with Tut
            Produce’s premium selection! Our offerings, including the crisp Red
            Onion, the luscious Golden Onion, the delightful Sweet Potatoes, and
            the zesty Spring Onion, are cultivated with utmost care and
            precision. Each product is a testament to our two-decade-long
            journey of blending ancestral wisdom with contemporary techniques.
            Explore our collection and savor the exceptional quality and flavor
            that is rooted in our legacy!
          </p>
          <div className="flex flex-col">
            <Swiper
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 1, // Mobile
                },
                768: {
                  slidesPerView: 2, // Tablets
                },
                1024: {
                  slidesPerView: 3, // Desktops
                },
              }}
              spaceBetween={10}
              centeredSlides={false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {products.map((product, index) => (
                <SwiperSlide key={index}>
                  <Product
                    name={product.name}
                    image={product.image}
                    onClick={() => openModal(product)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Modal */}
            {modalOpen && selectedProduct && (
              <Modal onClose={() => setModalOpen(false)}>
                <div className="flex flex-col lg:flex-row md:justify-between md:items-between  lg:w-[850px]">
                  <div className="lg:w-1/2 px-8 mb-3 md:mb-0 my-auto  flexitems-center justify-center  gs_reveal gs_reveal_fromLeft">
                    <img
                      src={selectedProduct.calendar}
                      alt={selectedProduct.name}
                      className="m-auto object-cover justify-center flex items-center my-auto"
                    />
                  </div>
                  <div className="lg:w-1/2 flex flex-col items-center justify-center">
                    <h2 className="md:mb-4 mb-3 text-xl md:text-3xl font-body text-secondary">
                      {selectedProduct.name}
                    </h2>
                    <div className="text-center md:text-left text-sm md:text-lg font-body md:mx-10 justify-center text-gray-700">
                      {selectedProduct.paragraph.map((element, index) => (
                        <React.Fragment>{element}</React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
