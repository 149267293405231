import React, { useEffect } from "react";
import { initScrollAnimations } from "../Components/AnimationUtils";

function VideoCard2({ videoOrder, VideoPath, title, paragraph }) {
  let orderVideo = "";
  let orderText = "";

  if (videoOrder === 1) {
    orderVideo = "order-1";
    orderText = "order-2";
  } else if (videoOrder === 2) {
    orderVideo = "order-2";
    orderText = "order-1";
  }
  useEffect(() => {
    initScrollAnimations();
  }, []);

  return (
    <div className=" mx-0 md:mt-20 mt-10">
      <div className="flex flex-col  lg:flex-row md:justify-between md:items-between mb-3 md:mb-8   ">
        <div
          className={`basis-1/2 px-8 mb-3 md:mb-0 order-1 lg:${orderVideo} gs_reveal gs_reveal_fromLeft`}
        >
          <video
            className=" w-full h-full lg:w-29 mx-auto object-cover  rounded-2xl"
              src={`https://res.cloudinary.com/ddoclltn4/video/upload/${VideoPath}`}
            alt="About Us"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>

        <div
          className={`text-center basis-1/2 my-auto order-2 md:${orderText} gs_reveal gs_reveal_fromRight`}
        >
          <h1 className="md:mb-12 mb-4  text-xl md:text-4xl font-Audiowide text-secondary">
            {title}
          </h1>

          <p className="text-center md:text-left text-sm md:text-lg font-body mx-0 md:mx-10  text-[rgba(38,18,91,0.70)] font-normal">
            {paragraph}
          </p>
        </div>
      </div>
    </div>
  );
}

export default VideoCard2;
