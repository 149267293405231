import "./ImageHeader.css";
// import "../Fonts/CustomFonts.css";
import React from "react";
function ImageHeader({ HeaderImageTitle, ImagePath }) {
  // debugger
  return (
    <div className="relative md:w-5/6 w-5/6 h-40 md:h-60 border rounded-xl overflow-hidden mb-8 m-auto">
      <img
        // src={require(`../assets/${ImagePath}`)}
        src={require(`../assets/${ImagePath}`)}
        alt="Spring Onions"
        className="w-full h-full object-cover"
        loading="lazy"
      />
      <div className="absolute inset-0 flex justify-center items-center z-10">
        <p className=" py-2 px-6 md:py-4 md:px-12 font-Audiowide text-center text-secondary bg-white rounded-xl border text-secondry text-lg md:text-3xl ">
          {HeaderImageTitle}
        </p>
      </div>
    </div>
  );
}

export default ImageHeader;
